import React from 'react'

function bodytext() {
  return (
    <>
    <br />
    <br />
      <div id='centertext'>
     <p> Get the inside scoop on your favorite apps with APK-Info.
      The ultimate app information provider for tech-savvy users
      </p></div>
        
    </>
  )
}

export default bodytext
